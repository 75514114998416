export enum LanguageEnum {
  Norwegian = 0,
  English = 1,
  French = 2,
  Swedish = 3,
  Danish = 4,
  Dutch = 5,
  German = 6,
  Spanish = 7,
  Finnish = 8,
  Portuguese = 9,
}
