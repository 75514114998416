import { Language } from '../models/language.model';
import { LanguageEnum } from './enums/language.enum';

export const NorwegianLanguage: Language = {
  id: LanguageEnum.Norwegian,
  code: 'no',
  locale: 'nb',
  name: 'Norsk',
  isDisabled: false,
  formatDateTime: {
    dateTime: 'dd.MM.yyyy HH:mm',
    dayMonth: 'dd.MM',
    fullDateTime: 'dd.MM.yyyy HH:mm:ss',
    shortDate: 'dd.MM.yyyy',
  },
  formatNumber: 'nb-no',
};

export const EnglishLanguage: Language = {
  id: LanguageEnum.English,
  code: 'en',
  locale: 'en-US',
  name: 'English',
  isDisabled: false,
  formatNumber: 'en-GB',
  formatDateTime: {
    dateTime: 'dd/MM/yy HH:mm',
    dayMonth: 'dd/MM',
    fullDateTime: 'dd/MM/yy HH:mm:ss',
    shortDate: 'dd/MM/yy',
  },
};

export const SwedishLanguage: Language = {
  id: LanguageEnum.Swedish,
  code: 'sv',
  locale: 'sv',
  name: 'Svenska',
  isDisabled: false,
  formatDateTime: {
    dateTime: 'yyyy-MM-dd HH:mm',
    dayMonth: 'MM-dd',
    fullDateTime: 'yyyy-MM-dd HH:mm:ss',
    shortDate: 'yyyy-MM-dd',
  },
  formatNumber: 'sv-SE',
};

export const DanishLanguage: Language = {
  id: LanguageEnum.Danish,
  code: 'da',
  locale: 'da',
  name: 'Dansk',
  isDisabled: false,
  formatDateTime: {
    dateTime: 'yyyy-MM-dd HH:mm',
    dayMonth: 'MM-dd',
    fullDateTime: 'yyyy-MM-dd HH:mm:ss',
    shortDate: 'yyyy-MM-dd',
  },
  formatNumber: 'da-DK',
};

export const GermanLanguage: Language = {
  id: LanguageEnum.German,
  code: 'de',
  locale: 'de',
  name: 'Deutsch',
  isDisabled: false,
  formatDateTime: {
    dateTime: 'dd.MM.yyyy HH:mm',
    dayMonth: 'dd.MM',
    fullDateTime: 'dd.MM.yyyy HH:mm:ss',
    shortDate: 'dd.MM.yyyy',
  },
  formatNumber: 'de-DE',
} as const;

export const SpanishLanguage: Language = {
  id: LanguageEnum.Spanish,
  code: 'es',
  locale: 'es',
  name: 'Español',
  isDisabled: false,
  formatDateTime: {
    dateTime: 'dd-MM-yy HH:mm',
    dayMonth: 'dd-MM',
    fullDateTime: 'dd-MM-yy HH:mm:ss',
    shortDate: 'dd-MM-yy',
  },
  formatNumber: 'es-ES',
};

export const PortugueseLanguage: Language = {
  id: LanguageEnum.Portuguese,
  code: 'pt',
  locale: 'pt',
  name: 'Português',
  isDisabled: false,
  formatDateTime: {
    dateTime: 'dd/MM/yy HH:mm',
    dayMonth: 'dd/MM',
    fullDateTime: 'dd/MM/yy HH:mm:ss',
    shortDate: 'dd/MM/yyyy',
  },
  formatNumber: 'pt-BR',
} as const;

export const SupportedLanguageConstants = [
  NorwegianLanguage,
  EnglishLanguage,
  SwedishLanguage, // Swedish
  DanishLanguage,
  GermanLanguage,
  SpanishLanguage, // Spanish
  PortugueseLanguage,
];
